import React, { useEffect, useState } from 'react'
import './css/screen3.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import policyEn from "locales/policies/policy.en.json";
import policyFr from "locales/policies/policy.fr.json";
import policyAr from "locales/policies/policy.ar.json";
import policyPg from "locales/policies/policy.pg.json";
import policySwc from "locales/policies/policy.swc.json";
import policyAm from "locales/policies/policy.am.json";
import policyEs from "locales/policies/policy.es.json";
import policySn from "locales/policies/policy.sn.json";
import policyTp from "locales/policies/policy.tp.json";
import policyKg from "locales/policies/policy.kg.json";
import policyId from "locales/policies/policy.id.json";
import policyKm from "locales/policies/policy.km.json";
import policyRu from "locales/policies/policy.ru.json";

const languageMap = new Map([
  ["0", policyEn],
  ["1", policyFr],
  ["2", policyAr],
  ["3", policyPg],
  ["4", policySwc],
  ["5", policyAm],
  ["6", policyEs],
  ["7", policySn],
  ["8", policyTp],
  ["9", policyKg],
  ["10", policyId],
  ["11", policyKm],
  ["12", policyRu]
]);

const Policy = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [policy, setPolicy] = useState({});

  useEffect(() => {
    const languageId = localStorage.getItem("selectedLanguage") || "1";
    const mappedPolicy = languageMap.get(languageId);

    if (mappedPolicy) {
      setPolicy(mappedPolicy);
    } else {
      setPolicy(languageMap.get("1"));
    }
  }, []);

  return (
    <div className='home'>
      <div className="main">
        <div className="container-fluid box-wrapper difficult">
          <div className="page-header pb-5">
            <div className="box-wrapper mx-auto text-center position-relative header-container">
              <button type="button" className="icon-button" onClick={() => navigate(-1)}>
                <img src="/image/back.png" className='arrow' alt="back" />
              </button>
              <span className="levels-title">{t('12')}</span>
            </div>
          </div>
          <div className="home-profile">
            <h4 className="text-center">{policy.heading}</h4>
            <div className="my-4">
              {policy?.subheadings?.map(section => (
                <div key={section.id} className="mb-3">
                  <h6>
                    {section.id}. {section.title}
                  </h6>
                  {section.subheadings.map(subsection => (
                    <p key={subsection.id} className="policy-description mb-0">
                      <span>{subsection.id ? `${subsection.id}. ` : ""}</span><span>{subsection.content}</span>
                    </p>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Policy